import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { forOwn, get } from 'lodash';
import { useForm } from 'react-hook-form';

import useWidth from '../../Hooks/useWidth';
import useSpace from '../../Hooks/useSpace';
import ButtonWithLoader from '../ButtonWithLoader';
import SpacePrivacyCheckboxController from '../Auth/PrivacyCheckboxController/SpacePrivacyCheckboxController';
import ApiErrorsFormatter from '../../Utils/ApiErrorsFormatter';
import { createEntitySubscription } from '../../Services/Api';
import { Images } from '../../Themes';
import Dialog from '../DesignSystem/Dialog';
import { useSnackbar } from '../../Utils/SnackbarContext';

export const entitySubscriptionModalCookieName = (spaceId: string) =>
  `entity_subscription_modal_space_${spaceId}`;

function EntitySubscriptionModal({ ...props }: any) {
  const intl = useIntl();
  const { showSnackbar } = useSnackbar();
  const { onClose, customRedirectTo } = props;
  const { search } = useLocation();
  const params = qs.parse(search?.slice(1));
  const { space, refetch } = useSpace();
  const { redirectTo } = params;
  const fullScreen = useWidth('down', 'sm');
  const [fetching, setFetching] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: {
      invitation_code: params.invitation_code,
      // NOTE: this field doesn't exist in the API, it's just used for frontend validation
      space_terms_of_service_accepted_semver: false,
    },
  });

  const handleFormSubmit = async (data: any) => {
    setFetching(true);
    const response = await createEntitySubscription({ data });

    setFetching(false);

    if (response.ok) {
      refetch();
      showSnackbar(
        intl.formatMessage({ id: 'AUTH.SIGNUP_EXTRAS.WELCOME_TOAST' }, { spaceName: space.name }),
      );
      if (redirectTo || customRedirectTo) {
        navigate(redirectTo || customRedirectTo);
      }
      onClose();
    } else {
      forOwn(ApiErrorsFormatter(response.data.errors), (message, key) => setError(key, message));
      showSnackbar(get(response, 'data.errors[0]') || '', 'error');
    }
  };

  return (
    <Dialog
      id="entity_subscription_form_dialog"
      label={intl.formatMessage({ id: 'AUTH.ENTITY_SUBSCRIPTION_FORM.TITLE' })}
      fullScreen={fullScreen}
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}
      variant="small"
      dismissible={space.public}
      title={
        <FormattedMessage
          id="AUTH.ENTITY_SUBSCRIPTION_FORM.TITLE"
          values={{ spaceName: space.name }}
        />
      }
      actions={
        <>
          {space.public && (
            <Button onClick={onClose} color="primary" variant="outlined" size="large">
              <FormattedMessage id="BUTTONS.SKIP" />
            </Button>
          )}
          <ButtonWithLoader
            variant="contained"
            color="primary"
            size="large"
            disabled={fetching}
            isFetching={fetching}
            type="submit"
            onClick={handleSubmit(handleFormSubmit)}
          >
            <FormattedMessage id="BUTTONS.JOIN" />
          </ButtonWithLoader>
        </>
      }
      {...props}
    >
      <Box>
        <input type="hidden" name="invitation_code" {...register} />
        <img
          src={Images.modals.entity_subscription}
          alt={intl.formatMessage({ id: 'AUTH.ENTITY_SUBSCRIPTION_FORM.TITLE' })}
          width="100%"
        />

        <Typography variant="body1" gutterBottom>
          <FormattedMessage
            id="AUTH.ENTITY_SUBSCRIPTION_FORM.CONTENT"
            values={{
              spaceName: space.name,
              br: () => <br />,
              strong: (chunks: any) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>

        <Box sx={{ mt: 1, mb: 3 }}>
          <SpacePrivacyCheckboxController
            space={space}
            control={control}
            error={!!get(errors, 'space_terms_of_service_accepted_semver.message')}
            helperText={get(errors, 'space_terms_of_service_accepted_semver.message')}
            // NOTE: this fields does not exist in the backend, for now it's just a frontend validation
            name="space_terms_of_service_accepted_semver"
          />
        </Box>
      </Box>
    </Dialog>
  );
}

export default EntitySubscriptionModal;
