import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Breakpoint } from '@mui/system';

function useWidth(direction: 'up' | 'down' | 'only' | 'not' = 'up', mediaquery: Breakpoint = 'sm') {
  const theme = useTheme();
  const check = useMediaQuery(theme.breakpoints[direction](mediaquery), { noSsr: true });

  return check;
}

export default useWidth;
