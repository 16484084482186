import React, { useMemo, useState } from 'react';
import { AppBar, Box, Button, IconButton, Toolbar, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import InsertChart from '@mui/icons-material/InsertChart';
import Menu from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';

import DrawerMenu from '../DrawerMenu';
import EngageLogo from '../EngageLogo';
import SignUpButton from '../SignUpButton';
import LanguageMenu from '../LanguageMenu/LanguageMenu';
import NavbarLinkTab from '../NavBarLinkTab';
import Hidden from '../../DesignSystem/Hidden';
import { Roles } from '../../../Utils/Types';
import { isAccessingViaCustomDomain, isPageSpecificToASpace } from '../../../Utils';
import ModalsActions from '../../../Redux/ModalsRedux';
import ProfileMenu from '../ProfileMenu';
import { MenuItemToMuiMenuItemProps, generateMuiMenuItems } from '../../../Utils/Space/navigation';

function NavBar({
  role,
  isLoggedIn,
  pictureS3Key,
  filename,
  space,
  fullName,
  verified,
  initials,
  isLoading,
}: any) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const errorPageStatus = useSelector((state: any) => state.errorPages.status);
  const [open, setOpen] = useState(false);

  const hideNavElements =
    location.pathname === '/home' || (location.pathname === '/' && !isAccessingViaCustomDomain());
  const isAdministrationButtonsVisible = isPageSpecificToASpace() && role !== Roles.CITIZEN;
  const hideJoinButton = useSelector((state: any) =>
    get(state, `modals[entitySubscription-${space.id}]`, true),
  );
  const isMultilingual = space?.locales?.length > 1;

  const showModal = () => {
    dispatch(ModalsActions.toggleModal(`entitySubscription-${space.id}`, true));
  };

  const LanguageButton = {
    id: 'language-menu',
    component: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingInlineEnd: 1,
        }}
      >
        <LanguageMenu />
      </Box>
    ),
  };

  const navLinks = [];

  const spaceMenuItems = useMemo(() => generateMuiMenuItems(space, intl), [space, intl]);
  if (!hideNavElements) {
    navLinks.push(...spaceMenuItems);
  }

  navLinks.push({
    id: 'divider',
    component: (
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          height: 0,
        }}
      />
    ),
  });

  if (!hideNavElements) {
    if (isLoggedIn) {
      if (isAdministrationButtonsVisible) {
        navLinks.push({
          id: 'dashboard',
          label: intl.formatMessage({ id: 'NAVBAR.DASHBOARD' }),
          href: `/${space.slug}/dashboard`,
          icon: <InsertChart />,
          'data-testid': 'dashboard-button',
        });
      }

      if (isMultilingual) {
        navLinks.push(LanguageButton);
      }

      if (space.slug && !hideJoinButton) {
        navLinks.push({
          id: 'joinbutton',
          component: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button onClick={showModal} color="primary" variant="contained" size="medium">
                <FormattedMessage id="BUTTONS.JOIN" />
              </Button>
            </Box>
          ),
        });
      }

      if (space.slug) {
        navLinks.push({
          id: 'profile-menu',
          component: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ProfileMenu
                fullName={fullName}
                initials={initials}
                userPictureS3Key={pictureS3Key}
                filename={filename}
                verified={verified}
              />
            </Box>
          ),
        });
      }
    } else {
      if (isMultilingual) {
        navLinks.push(LanguageButton);
      }

      navLinks.push({
        id: 'signup',
        component: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SignUpButton />
          </Box>
        ),
      });
    }
  }

  const renderNavLink = (link: MenuItemToMuiMenuItemProps, index: number) => {
    if (link.href) {
      const { href, label, ariaLabel, component, id, ...rest } = link;

      return (
        <NavbarLinkTab
          {...(component ? { component, href } : { to: href })}
          label={label}
          value={href}
          key={`${id}-${index}`}
          aria-label={ariaLabel || label}
          {...rest}
        />
      );
    }

    return <NavbarLinkTab key={`${link.id}-${index}`} render={link.component} />;
  };

  if (errorPageStatus === 500) return null;

  const tabValue =
    navLinks.find((link) => link.href && location.pathname.startsWith(link.href))?.href || false;

  return (
    <>
      <AppBar position="fixed" color="inherit" component="nav">
        <Toolbar style={{ flex: 1, overflow: 'visible' }}>
          <Box sx={{ mr: 3 }}>
            <EngageLogo currentEntity={space} isLoading={isLoading} />
          </Box>

          {space.slug && !hideNavElements && (
            <Hidden breakpoint="lg" direction="down">
              <Tabs
                value={tabValue}
                sx={{
                  flex: 1,
                  height: 64,
                  root: { height: 64 },
                  overflow: 'visible',
                  '& .MuiTabs-scroller': {
                    overflow: 'visible !important',
                  },
                }}
              >
                {navLinks.map(renderNavLink)}
              </Tabs>
            </Hidden>
          )}

          <Hidden breakpoint="lg" direction="up">
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'end',
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setOpen(true)}
                size="large"
              >
                <Menu />
              </IconButton>
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>

      <DrawerMenu
        space={space}
        role={role}
        userPictureS3Key={pictureS3Key}
        open={open}
        handleClose={() => setOpen(false)}
        fullName={fullName}
        initials={initials}
        isLoggedIn={isLoggedIn}
      />
    </>
  );
}

export default NavBar;
