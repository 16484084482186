import { Breakpoint } from '@mui/system';
import { PropsWithChildren } from 'react';

import useWidth from '../../../Hooks/useWidth';

interface HiddenProps {
  direction: 'up' | 'down';
  breakpoint: Breakpoint;
}

const Hidden = ({ children, direction, breakpoint }: PropsWithChildren<HiddenProps>) => {
  const matches = useWidth(direction, breakpoint);

  if (matches) {
    return null;
  }

  return children;
};

export default Hidden;
